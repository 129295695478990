<script lang="ts">
  import {
    Autocomplete,
    type AutocompleteOption,
    getModalStore,
    getToastStore,
    popup,
    type PopupSettings,
  } from '@skeletonlabs/skeleton'
  import { onMount, type SvelteComponent } from 'svelte'

  import { loggedInStore } from '@stores/logged-in-store'
  import { loadUsers, usersStore } from '@stores/user-store'

  const toastStore = getToastStore()

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
    newUserId?: number | null
  }

  let { parent, newUserId = $bindable(null) }: Props = $props()

  let userInput = $state('')

  let userOptions: AutocompleteOption<string>[] = $state([])

  onMount(() => {
    loadUsers()
  })

  usersStore.subscribe(($usersStore) => {
    userOptions = [...$usersStore].map((user) => ({
      value: `${user.id}`,
      label: `${user.fullName} (${user.username})`,
      keywords: [user.username, user.fullName].join(', '),
    }))
  })

  const popupSettings: PopupSettings = {
    event: 'focus-click',
    target: 'popupAutocomplete',
    placement: 'bottom',
  }

  const onPopupDemoSelect = (event: CustomEvent<AutocompleteOption<string | null>>) => {
    newUserId = parseInt(event.detail.value ?? '')
    const user = $usersStore.find((user) => user.id == newUserId)
    userInput = `${user?.fullName ?? null} (${user?.username ?? null})`
  }

  const onFormCancel = () => {
    modalStore.close()
  }
  const onFormSubmit = () => {
    if (!newUserId) {
      toastStore.trigger({ message: 'Please select a user', background: 'variant-filled-error' })
      return
    }
    const user = $usersStore.find((user) => user.id == newUserId)
    if (userInput != `${user?.fullName ?? null} (${user?.username ?? null})`) {
      toastStore.trigger({
        message: 'Please select a valid user',
        background: 'variant-filled-error',
      })
      return
    }
    if ($modalStore[0].response) {
      $modalStore[0].response(newUserId)
    }
    modalStore.close()
  }

  const modalStore = getModalStore()

  $usersStore.filter((user) => user.id != $loggedInStore.userId)
</script>

{#if $modalStore[0]}
  <div class="card w-modal space-y-4 p-4 shadow-xl">
    <header class="text-2xl font-bold">{$modalStore[0].title}</header>
    <article class="space-y-4 border border-surface-500 p-4 rounded-container-token">
      {$modalStore[0].body}
      <div>
        <input
          class="autocomplete input my-0 box-border flex w-fit min-w-96 px-2 py-1"
          type="search"
          name="user-to-transfer-to"
          bind:value={userInput}
          placeholder="Search..."
          use:popup={popupSettings}
          required
        />
        <div
          data-popup="popupAutocomplete"
          class="card max-h-48 w-full max-w-sm overflow-y-auto p-4"
          tabindex="-1"
        >
          <Autocomplete
            bind:input={userInput}
            options={userOptions}
            on:selection={onPopupDemoSelect}
          />
        </div>
      </div>
    </article>
    <footer class="modal-footer {parent.regionFooter}">
      <button class="btn {parent.buttonNeutral}" onclick={onFormCancel}
        >{parent.buttonTextCancel}</button
      >
      <button class="btn {parent.buttonPositive}" onclick={onFormSubmit}
        >{parent.buttonTextConfirm}</button
      >
    </footer>
  </div>
{/if}
