<script lang="ts">
  import './styles.css'
  import { browser } from '$app/environment'
  import { afterNavigate, beforeNavigate, goto } from '$app/navigation'
  import { page } from '$app/stores'
  import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom'
  import {
    getToastStore,
    initializeStores,
    Modal,
    type ModalComponent,
    storePopup,
    Toast,
  } from '@skeletonlabs/skeleton'
  import camelcaseKeys from 'camelcase-keys'
  import posthog from 'posthog-js'
  import { onMount, type Snippet } from 'svelte'

  import OptimizationDatasetModal from './optimization-datasets/OptimizationDatasetModal.svelte'
  import StorageFormModal from './storage-configs/StorageFormModal.svelte'
  import ChangeUserAssociationModal from './user-associations/ChangeUserAssociationModal.svelte'
  import ApproveModal from '@components/ApproveModal.svelte'
  import CancelModal from '@components/CancelModal.svelte'
  import DeleteModal from '@components/DeleteModal.svelte'
  import Header from '@components/Header.svelte'
  import TransferOwnershipModal from '@components/TransferOwnershipModal.svelte'
  import { loggedInStore, User } from '@stores/logged-in-store'
  import { type Config, configureFirebase, getConfig, isHirundoEmail } from '@utils'
  interface Props {
    children?: Snippet
  }

  let { children }: Props = $props()

  initializeStores()
  storePopup.set({ computePosition, autoUpdate, offset, shift, flip, arrow })

  const modalRegistry: Record<string, ModalComponent> = {
    // Set a unique modal ID, then pass the component reference
    deleteModal: { ref: DeleteModal },
    cancelModal: { ref: CancelModal },
    approveModal: { ref: ApproveModal },
    // ⬆️ TODO: Make modal code less repetitive (DRY it up a bit)
    changeUserAssociationModal: { ref: ChangeUserAssociationModal },
    storageFormModal: { ref: StorageFormModal },
    optimizationDatasetFormModal: { ref: OptimizationDatasetModal },
    transferOwnershipModal: { ref: TransferOwnershipModal },
  }

  let initialized = $state(false)
  let config: Config | undefined = $state()

  const toastStore = getToastStore()

  onMount(async () => {
    config = await getConfig(toastStore)
    configureFirebase(config)

    if (config.postHogId) {
      posthog.init(config.postHogId, {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'always',
        capture_pageview: false,
        capture_pageleave: false,
      })
    }

    initialized = false
    const authUserString = localStorage.getItem('authUser')
    if (authUserString) {
      const authUser = JSON.parse(authUserString)
      loggedInStore.update(User.login(authUser.email, camelcaseKeys(authUser.loginResult)))
    }
    const url = $page.url
    if (url.pathname === '/login' && $loggedInStore.loggedIn) {
      await goto('/')
    } else if (!['/login', '/signup'].includes(url.pathname) && !$loggedInStore.loggedIn) {
      await goto('/login', { invalidateAll: true })
    } else if (
      url.pathname === '/dataset-optimization-runs' &&
      $loggedInStore.loggedIn &&
      !isHirundoEmail($loggedInStore.username)
    ) {
      await goto('/')
    }
    initialized = true
  })

  if (browser) {
    beforeNavigate(() => posthog.capture('$pageleave'))
    afterNavigate(() => posthog.capture('$pageview', { version: VERSION }))
  }
</script>

<svelte:head>
  {#if !config?.airGapped}
    <link
      type="text/css"
      rel="stylesheet"
      href="https://www.gstatic.com/firebasejs/ui/6.0.1/firebase-ui-auth.css"
    />
  {/if}
</svelte:head>

<div class="app overflow-auto">
  {#if initialized}
    <Header />

    <main>
      {@render children?.()}
    </main>
    {#if $loggedInStore.loggedIn}
      <Modal components={modalRegistry} buttonPositive="variant-filled-primary" />
    {/if}
    <Toast position="tr" zIndex="z-[1000]" width="max-w-[640px] [word-break:break-word]" />
  {/if}

  <footer>
    <p>Visit <a href="https://www.hirundo.io/blog">hirundo.io</a> to read our latest blog posts</p>
  </footer>
</div>

<style>
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }

  footer a {
    font-weight: bold;
  }

  @media (min-width: 480px) {
    footer {
      padding: 12px 0;
    }
  }
</style>
