<script lang="ts">
  interface Props {
    currentColor?: string
  }

  let { currentColor = 'white' }: Props = $props()
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="400px"
  height="100px"
  viewBox="0 0 215 51"
  fill="none"
  color={currentColor}
  preserveAspectRatio="xMidYMid meet"
  aria-hidden="true"
  role="img"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.93058 0.851562C23.7439 8.85526 38.3927 21.1386 0 27.0145C11.9403 26.7509 16.0229 29.1876 2.53953 38.8401C26.8854 25.3373 37.6207 18.9022 36.2775 50.1173C39.5035 44.3783 48.3987 24.6936 40.1275 21.8139C40.7834 21.0332 41.7669 19.7674 42.4649 18.4488C43.8504 17.8475 44.8552 17.0139 45.6697 15.9484C44.7494 15.7374 43.9774 15.6849 43.2582 15.7057C43.2369 15.4105 43.1737 15.1257 43.0359 14.8725C42.1157 13.132 37.9486 13.4273 36.4361 13.5959C36.9331 5.05137 12.448 1.24389 5.93058 0.851562Z"
    fill="currentColor"
  ></path>
  <path
    d="M58.6612 38.3125V13.3935H63.9297V23.675H74.6249V13.3935H79.8812V38.3125H74.6249V28.0188H63.9297V38.3125H58.6612Z"
    fill="currentColor"
  ></path>
  <path d="M89.4936 13.3935V38.3125H84.225V13.3935H89.4936Z" fill="currentColor"></path>
  <path
    d="M93.8282 38.3125V13.3935H103.66C105.541 13.3935 107.148 13.7302 108.478 14.4034C109.816 15.0686 110.834 16.0136 111.532 17.2384C112.238 18.4552 112.59 19.8869 112.59 21.5336C112.59 23.1883 112.234 24.6119 111.52 25.8043C110.806 26.9887 109.772 27.8972 108.417 28.5299C107.07 29.1626 105.44 29.4789 103.526 29.4789H96.9431V25.2446H102.674C103.68 25.2446 104.515 25.1067 105.18 24.831C105.846 24.5552 106.34 24.1415 106.665 23.5899C106.997 23.0383 107.164 22.3528 107.164 21.5336C107.164 20.7062 106.997 20.0086 106.665 19.4408C106.34 18.8729 105.842 18.443 105.168 18.151C104.503 17.8509 103.664 17.7008 102.65 17.7008H99.0967V38.3125H93.8282ZM107.285 26.9724L113.479 38.3125H107.663L101.603 26.9724H107.285Z"
    fill="currentColor"
  ></path>
  <path
    d="M131.706 13.3935H136.974V29.5763C136.974 31.3933 136.54 32.9832 135.672 34.3459C134.812 35.7087 133.608 36.7713 132.058 37.5338C130.509 38.2882 128.704 38.6654 126.644 38.6654C124.575 38.6654 122.767 38.2882 121.217 37.5338C119.668 36.7713 118.463 35.7087 117.603 34.3459C116.744 32.9832 116.314 31.3933 116.314 29.5763V13.3935H121.582V29.1261C121.582 30.0751 121.789 30.9187 122.203 31.6569C122.625 32.3951 123.217 32.9751 123.979 33.3969C124.742 33.8187 125.63 34.0296 126.644 34.0296C127.666 34.0296 128.554 33.8187 129.309 33.3969C130.071 32.9751 130.659 32.3951 131.073 31.6569C131.495 30.9187 131.706 30.0751 131.706 29.1261V13.3935Z"
    fill="currentColor"
  ></path>
  <path
    d="M162.152 13.3935V38.3125H157.601L146.76 22.6286H146.577V38.3125H141.309V13.3935H145.932L156.688 29.0652H156.907V13.3935H162.152Z"
    fill="currentColor"
  ></path>
  <path
    d="M175.338 38.3125H166.505V13.3935H175.411C177.918 13.3935 180.075 13.8924 181.884 14.8901C183.693 15.8797 185.084 17.3033 186.058 19.1609C187.039 21.0185 187.53 23.2411 187.53 25.8287C187.53 28.4244 187.039 30.6551 186.058 32.5208C185.084 34.3865 183.685 35.8182 181.86 36.8159C180.043 37.8137 177.869 38.3125 175.338 38.3125ZM171.773 33.7984H175.119C176.677 33.7984 177.987 33.5226 179.049 32.971C180.12 32.4113 180.923 31.5474 181.458 30.3793C182.002 29.2031 182.274 27.6863 182.274 25.8287C182.274 23.9873 182.002 22.4826 181.458 21.3146C180.923 20.1465 180.124 19.2866 179.061 18.735C177.999 18.1835 176.689 17.9077 175.131 17.9077H171.773V33.7984Z"
    fill="currentColor"
  ></path>
  <path
    d="M214.332 25.853C214.332 28.5704 213.817 30.8822 212.787 32.7885C211.765 34.6947 210.369 36.1508 208.601 37.1566C206.841 38.1543 204.862 38.6532 202.663 38.6532C200.449 38.6532 198.461 38.1503 196.701 37.1444C194.941 36.1386 193.55 34.6826 192.528 32.7763C191.506 30.8701 190.995 28.5623 190.995 25.853C190.995 23.1356 191.506 20.8238 192.528 18.9176C193.55 17.0113 194.941 15.5593 196.701 14.5616C198.461 13.5558 200.449 13.0528 202.663 13.0528C204.862 13.0528 206.841 13.5558 208.601 14.5616C210.369 15.5593 211.765 17.0113 212.787 18.9176C213.817 20.8238 214.332 23.1356 214.332 25.853ZM208.99 25.853C208.99 24.0928 208.727 22.6084 208.199 21.3997C207.68 20.1911 206.946 19.2745 205.997 18.6499C205.048 18.0253 203.937 17.713 202.663 17.713C201.39 17.713 200.278 18.0253 199.329 18.6499C198.38 19.2745 197.642 20.1911 197.115 21.3997C196.596 22.6084 196.336 24.0928 196.336 25.853C196.336 27.6132 196.596 29.0977 197.115 30.3063C197.642 31.515 198.38 32.4316 199.329 33.0562C200.278 33.6808 201.39 33.9931 202.663 33.9931C203.937 33.9931 205.048 33.6808 205.997 33.0562C206.946 32.4316 207.68 31.515 208.199 30.3063C208.727 29.0977 208.99 27.6132 208.99 25.853Z"
    fill="currentColor"
  ></path>
</svg>
