<script lang="ts">
  import { getModalStore, getToastStore } from '@skeletonlabs/skeleton'
  import type { SvelteComponent } from 'svelte'

  import Input from './Input.svelte'

  interface Props {
    /** Exposes parent props to this component. */
    parent: SvelteComponent
  }

  let { parent }: Props = $props()

  let cancelTest = $state('')

  const toastStore = getToastStore()
  let activeToast: string | null = null

  const onFormCancel = () => {
    if ($modalStore[0].response) {
      $modalStore[0].response(false)
    }
    modalStore.close()
  }
  const onFormSubmit = () => {
    if (activeToast) {
      toastStore.close(activeToast)
    }
    if (singleItemToCancel) {
      if (cancelTest != $modalStore[0].meta) {
        activeToast = toastStore.trigger({
          message: "You didn't type the name of the item correctly",
          background: 'variant-filled-error',
        })
        return
      }
    } else {
      if (cancelTest != 'CANCEL') {
        activeToast = toastStore.trigger({
          message: "You didn't type CANCEL correctly",
          background: 'variant-filled-error',
        })
        return
      }
    }
    if ($modalStore[0].response) {
      $modalStore[0].response(true)
    }
    modalStore.close()
  }

  const modalStore = getModalStore()

  const singleItemToCancel = typeof $modalStore[0].meta == 'string'
</script>

{#if $modalStore[0]}
  <div class="card w-modal space-y-4 p-4 shadow-xl">
    <header class="text-2xl font-bold">{$modalStore[0].title}</header>
    <article class="space-y-4 border border-surface-500 p-4 rounded-container-token">
      {$modalStore[0].body}
      {#if singleItemToCancel}
        <pre>{$modalStore[0].meta}</pre>
      {:else}
        <ol class="list my-3">
          {#each $modalStore[0].meta as meta, number}
            <li>
              <span class="variant-soft-primary badge-icon p-4">{number + 1}.</span>
              <span class="flex-auto overflow-x-hidden"><pre>{meta}</pre></span>
            </li>
          {/each}
        </ol>
      {/if}
      {#if singleItemToCancel}
        <p>Please confirm you want to cancel this run by typing the name of the item</p>
        <Input type="text" bind:value={cancelTest} />
      {:else}
        <p>Please confirm you want to cancel these run by typing "CANCEL"</p>
        <Input type="text" bind:value={cancelTest} />
      {/if}
    </article>
    <footer class="modal-footer {parent.regionFooter}">
      <button class="btn {parent.buttonNeutral}" onclick={onFormCancel}
        >{parent.buttonTextCancel}</button
      >
      <button class="btn {parent.buttonPositive}" onclick={onFormSubmit}
        >{parent.buttonTextConfirm}</button
      >
    </footer>
  </div>
{/if}
